import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

import { getExperiences } from "../../../../../services/api/v2/experience";

import Experience from "../../../../../types/v2/experience";
import { useAuth0 } from "@auth0/auth0-react";

const TypeControl = ({
  value,
  handleChange
}: {
  value: string;
  handleChange: (value: string) => void;
}) => {
  const { getAccessTokenSilently } = useAuth0();
  
  const [options, setOptions] = useState<Experience[] | undefined>(undefined)

  useEffect(() => {
    if (options) return;

    getExperiences(getAccessTokenSilently)
      .then(async (response: Response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      }).then((experiences: Experience[]) => {
        const opts = [
          { experience_id: -1, code: 'all', name: 'All' } as Experience,
          ...experiences
        ]
        setOptions(opts);
        handleChange('all');
      }).catch((error) => console.error(error.message));
  }, []);

  return (
    <Form.Select
      value={value}
      onChange={(e) => handleChange(e.target.value)}
      className={`btn-primary`}
      style={{
        width: '160px',
        marginLeft: '15px',
        backgroundImage: `url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23adb5bd' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E")`
      }}
    >
      {options?.map((option: Experience) => {
        return <option
          key={`typeOption${option.experience_id}`}
          value={option.code}>
            {option.name}
        </option>  
      })}
    </Form.Select>
  )
}

export default TypeControl;