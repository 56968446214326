import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { faCircleXmark, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useDebounce from '../hooks/useDebounce';
import SearchDropdown from './dropdown';

import { ReservationSearchTypes } from '../../../types/v2/reservation';

import styles from "./index.module.css";

export interface SearchMeta {
  active: boolean,
  query: string,
  type: ReservationSearchTypes
}

export type SearchHandle = {
  clear: () => void;
}

export const defaultSearchValues: SearchMeta = {
  active: false,
  query: "",
  type: "email"
};

const Search = forwardRef(({
  onSearchChange
} : {
  onSearchChange: (search: SearchMeta) => void
}, ref) => {
  const localStorageValue = localStorage.getItem('currentSearch');
  const initValues = localStorageValue ? JSON.parse(localStorageValue) : defaultSearchValues;

  const [query, setQuery] = useState<string>(initValues.query);
  const [type, setType] = useState<ReservationSearchTypes>(initValues.type);
  const [active, setActive] = useState<boolean>(initValues.active);

  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    clear() {
      setQuery(defaultSearchValues.query);
      setType(defaultSearchValues.type);
      setActive(defaultSearchValues.active);
      onSearchChange(defaultSearchValues);
      localStorage.setItem('currentSearch', JSON.stringify(defaultSearchValues));
    }
  }));

  const onClick = () => {
    if (!active) {
      setActive(true);
      const search: SearchMeta = {
        query,
        type,
        active: true
      };
      onSearchChange(search);
      localStorage.setItem('currentSearch', JSON.stringify(search));
      if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }
    }
  }

  const clear = () => {
    setActive(defaultSearchValues.active); // Activate search by default for mobile
    setQuery(defaultSearchValues.query)
    setType(defaultSearchValues.type);
    onSearchChange(defaultSearchValues);
    localStorage.setItem('currentSearch', JSON.stringify(defaultSearchValues));
  }

  const selectChange = (value: ReservationSearchTypes) => {
    setType(value);
    debouncedRequest();
  }
  
  const onChange = (e: any) => {
    const value = e.target.value;
    setQuery(value);
    debouncedRequest();
  };

  const debouncedRequest = useDebounce(() => {
    const search = {
      query,
      type,
      active
    };
    onSearchChange(search);
    localStorage.setItem('currentSearch', JSON.stringify(search));
  });

  return (
    <div className={styles.container} onClick={onClick}>
      <SearchDropdown
        className={`${active ? styles.active : ""}`}
        type={type}
        handleChange={selectChange}
      />
      <div className={styles["search-box"]}>
        <input
          ref={inputRef}
          type="text"
          placeholder='SEARCH...'
          value={query}
          onChange={onChange}
          className={`${styles.search} ${active ? styles.active : ""}`}
        />
        <FontAwesomeIcon
          icon={active ? faCircleXmark : faSearch}
          className={styles.clear}
          style={{
            color: active ? '#000' : '#FFF',
            ...active ? { cursor: 'pointer' } : {},
          }}
          onClick={clear}
        />
      </div>
    </div>
  );
});

export default Search;