import { ReactNode, useContext, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import LoginButton from "../common/button/login";
import Loader from "../common/loader/loader";
import { getPermissions } from "../../services/api/v2/auth0";
import UserContext from "../../context/user";

const AuthenticationChecker = ({ children }: { children: ReactNode }) => {
  let { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const { loggedInUser, setUser } = useContext(UserContext);
  const searchParams = new URLSearchParams(document.location.search)

  if (searchParams.get('code')) {
    localStorage.setItem('code', searchParams.get('code') as string);
  }

  if (searchParams.get('state')) {
    localStorage.setItem('state', searchParams.get('state') as string);
  }

  useEffect(() => {
    if (isAuthenticated) {
      setUser(user);
      // getPermissions(getAccessTokenSilently, user?.sub as string)
      //   .then(async (response: Response) => {
      //     if (!response.ok) throw new Error(await response.text());
      //     return response.json();
      //   }).then((roles: any) => {
      //     setUser({
      //       ...user,
      //       roles: roles
      //     });
      //   }).catch((error) => console.error(error))
    }
  }, [isAuthenticated]);

  if (isLoading) {
    return <Loader
      isLoading={true}
      centered={true}
    />;
  }
  
  return (
    <>
      {isAuthenticated && user
        ? (
          <>
            {children}
          </>
        ) : (
          <div style={{
            height: '100%',
            textAlign: 'center',
            backgroundColor: 'black'
          }}>
            <div style={{
              margin: '0',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}>
              <p style={{
                fontSize: '100px',
                fontFamily: 'Teko'
              }}>
                HIGGS
              </p>
              <LoginButton/>
            </div>
          </div>
        )
      }
    </>
  );
};

export default AuthenticationChecker;
