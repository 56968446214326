import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";

import { getGameReportDayStats } from "../../../services/api/v2/stats";
import Loader from "../../common/loader/loader";
import Stat from "../../common/stat";

import { Reservation } from "../../../types/v2/reservation";
import { GameReport } from "../../../types/v2/gameReport";

import styles from "../index.module.css";
import { useAuth0 } from "@auth0/auth0-react";

const DailyGameReport = () => {
  const { getAccessTokenSilently } = useAuth0();
  
  const [stats, setStats] = useState<{ reservations: Reservation[], gameReports: GameReport[] }>({
    reservations: [],
    gameReports: []
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);
  
  useEffect(() => {
    setError(undefined);

    getGameReportDayStats(getAccessTokenSilently)
      .then(async (response: Response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      }).then((stats) => {
        setIsLoading(false);
        setStats(stats);
      }).catch((err) => {
        setIsLoading(false);
        setError(err);
      });
  }, [setStats, setIsLoading, setError]);

  let totalReservationReports = 0;
  stats.reservations.forEach((reservation: Reservation) => totalReservationReports += reservation.game_reports.length);

  const report = totalReservationReports === stats.gameReports.length ? styles.success : styles.danger;

  return (
    <>
      {error &&
      <Alert variant="danger">
        {error}
      </Alert>
      }
      <Loader isLoading={isLoading}>
        {stats &&
        <Stat 
          title="Yesterday's game reports"
        >
          <div className="mb-2">
            <span>Reservations: {stats.reservations.length}, Reports: <span className={report}>{totalReservationReports}</span></span>
          </div>
          <div>
            <span>Game Reports generated: <span className={report}>{stats.gameReports.length}</span></span>
          </div>
        </Stat>
        }
      </Loader>
    </>
  )
}

export default DailyGameReport;