import { apiBase, GetTokenType, SendRequest } from ".";

export const getGoogleAccounts = (getToken: GetTokenType) => {
  return SendRequest(
    getToken,
    'GET',
    `${apiBase}/google/accounts`
  );
}

export const getGoogleLocations = (getToken: GetTokenType, aid: string) => {
  return SendRequest(
    getToken,
    'GET',
    `${apiBase}/google/accounts/${aid}/locations`
  );
}

export const getGoogleReviews = (getToken: GetTokenType, aid: string, lid: string) => {
  return SendRequest(
    getToken,
    'GET',
    `${apiBase}/google/accounts/${aid}/locations/${lid}/reviews`
  );
}