import { apiBase, GetTokenType, SendRequest } from ".";

export const getGameReportDayStats = (getToken: GetTokenType) => {
  return SendRequest(
    getToken,
    'GET',
    `${apiBase}/stats/game-report/day-report`
  );
};

export const getGameReportStats = (getToken: GetTokenType) => {
  return SendRequest(
    getToken,
    'GET',
    `${apiBase}/stats/game-report/`
  );
};

export const getTimeslotHour = (getToken: GetTokenType) => {
  return SendRequest(
    getToken,
    'GET',
    `${apiBase}/stats/timeslot-hour/`
  );
};

export const getCustomerAverage = (getToken: GetTokenType) => {
  return SendRequest(
    getToken,
    'GET',
    `${apiBase}/stats/customer-average/`
  );
};

export const getCustomerSlot = (getToken: GetTokenType) => {
  return SendRequest(
    getToken,
    'GET',
    `${apiBase}/stats/customer-slot/`
  );
};

export const getMonthlyCustomerReservationStats = (getToken: GetTokenType, years?: string[]) => {
  let selection = '';
  years?.forEach((year: string, index: number) => selection += `${index === 0 ? "?year=" : "&year="}${year}`);
  return SendRequest(
    getToken,
    'GET',
    `${apiBase}/stats/monthly-customer-reservation${selection}`
  );
};

export const getRoomKStats = (getToken: GetTokenType) => {
  return SendRequest(
    getToken,
    'GET',
    `${apiBase}/stats/room-k/`
  );
}