const URI = process.env.REACT_APP_API_URL;
// const URI = 'api-tidalforcevr.loca.lt';
const HTTP_TRANSPORT = process.env.REACT_APP_IS_SECURED === 'false' ? 'http' : 'https';
const WS_TRANSPORT = process.env.REACT_APP_IS_SECURED === 'false' ? 'ws' : 'wss';
export const ROOT_URL = process.env.REACT_APP_API_ROOT || `${HTTP_TRANSPORT}://${URI}`;
export const WS_URL = process.env.REACT_APP_WEBSOCKET_ROOT || `${WS_TRANSPORT}://${URI}`;
export const PUBLIC_BASE_URL = process.env.REACT_APP_PUBLIC_BASE_URL || 'https://tidalforcevr.com';
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;

export const MOMENT_FORMAT: string = 'MMM Do YY, ha';

export const RESERVATION = {
  ALL: 'all',
  FUTURE: 'future',
  TODAY: 'today',
  DAY: 'day',
}
