import { GetTokenSilentlyOptions } from "@auth0/auth0-react";
import { AUTH0_AUDIENCE, ROOT_URL } from "../../../components/common/constants";

export const apiBase = '/v2';

export type GetTokenType = {
  (options: GetTokenSilentlyOptions & {
      detailedResponse: true;
  }): Promise<any>;
  (options?: GetTokenSilentlyOptions): Promise<string>;
  (options: GetTokenSilentlyOptions): Promise<any | string>;
}

export const SendRequest = async (
  getToken: GetTokenType,
  method: string,
  path: string,
  body = '{}',
  signal?: AbortSignal
) => {
  const token = await getToken({
    authorizationParams: {
      audience: AUTH0_AUDIENCE
    }
  });

  const configInit: RequestInit = {
    method,
    credentials: 'include',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'Authorization': `Bearer ${token}`,
      'Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    cache: 'default',
    signal
  };

  if (method !== 'GET') {
    configInit.body = body;
  }

  return fetch(
    `${ROOT_URL}${path}`,
    configInit
  );
};