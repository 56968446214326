import { useState } from "react";
import { Alert, Button, Col, Form } from "react-bootstrap";
import Select from "react-select";
import { Formik } from "formik";
import * as yup from 'yup';
import { useAuth0 } from "@auth0/auth0-react";

import { resetTv, showMessage } from "../../services/api/v2/tvApp";
import Loader from "../common/loader/loader";

import styles from "./index.module.css";

const Tvs = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [tv, setTv] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    message: yup.string().required('Message is required'),
    length: yup.number().required('Message is required'),
  });

  const initialValues = {
    name: undefined,
    message: undefined,
    length: 15
  };

  const submit = (form: any) => {
    setError(undefined);
    if (tv) {
      setIsLoading(true);
      showMessage(getAccessTokenSilently, tv, form.name, form.message, form.length)
        .then(() => {
          setIsLoading(false);
        }).catch((err) => {
          setError(err.message);
        });
    }
  }

  const reset = () => {
    setError(undefined);
    if (tv) {
      resetTv(getAccessTokenSilently, tv)
        .then(() => {
          setIsLoading(false);
        }).catch((err) => {
          setError(err.message);
        });
    }
  }

  return (
    <div className={styles.container}>
      {error &&
        <Alert
          variant="danger"
          dismissible={true}
        >
          {error}
        </Alert>
      }
      <div style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <h1>TV Controls</h1>
        <Select
          placeholder="Select TV"
          options={[
            { value: 1, label: "TV 1 (Left)" },
            { value: 2, label: "TV 2 (Middle)" },
            { value: 3, label: "TV 3 (Right)" },
          ]}
          styles={{
            control: (baseStyles: any) => ({
              ...baseStyles,
              borderRadius: 0,
              height: '37px',
              fontSize: '1rem',
              fontWeight: '400',
              lineHeight: '1.5',
              width: '200px'
            }),
            menu: (baseStyles: any) => ({
              ...baseStyles,
              color: "#000000",
              fontSize: '1rem',
              fontWeight: '400',
              lineHeight: '1.5'
            })
          }}
          onChange={(e) => setTv(e?.value)}
        />
      </div>
      {tv !== undefined &&
      <>
        <hr />
        <div className={styles.controls}>
          <div>
            <h2>Custom message</h2>
            <Formik
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={submit}
            >
              {(formikProps: any) => {
                return (
                  <Form
                    noValidate
                    onSubmit={formikProps.handleSubmit}
                    autoComplete="off"
                  >
                    <Form.Group as={Col} controlId="name">
                      <Form.Label>Name (text or url to image)</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        required
                        name="name"
                        value={formikProps.values.name || ''}
                        onChange={formikProps.handleChange}
                        isInvalid={!!formikProps.errors.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formikProps.errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} controlId="message">
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Message"
                        required
                        name="message"
                        value={formikProps.values.message || ''}
                        onChange={formikProps.handleChange}
                        isInvalid={!!formikProps.errors.message}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formikProps.errors.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} controlId="length">
                      <Form.Label>Length</Form.Label>
                      <div style={{
                        display: 'flex',
                        gap: '13px',
                        alignItems: 'center'
                      }}>
                        <Form.Control
                          type="text"
                          placeholder="Enter length in minutes"
                          required
                          name="length"
                          value={formikProps.values.length || ''}
                          onChange={formikProps.handleChange}
                          isInvalid={!!formikProps.errors.length}
                        />
                        <span>Minutes</span>
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {formikProps.errors.length}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <div>
                      <Button
                        variant="primary"
                        className="btn-white mt-3"
                        type="submit"
                        disabled={isLoading}
                      >
                        <Loader isLoading={isLoading}>SEND</Loader>
                      </Button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
          <div>
            <h2>Actions</h2>
            <Button
              onClick={reset}
            >
              RESET
            </Button>
          </div>
        </div>
      </>
      }
    </div>
  )
}

export default Tvs;