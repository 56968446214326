import React, { LegacyRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Dropdown } from "react-bootstrap";

import styles from './index.module.css';

const Profile = () => {
  const { user, logout } = useAuth0();

  if (!user) {
    return (
      <>
        Error: User information not readable.
      </>
    );
  }

  const CustomToggle = React.forwardRef(({ onClick }: { onClick: (e: React.MouseEvent) => void }, ref: LegacyRef<HTMLDivElement> | undefined) => (
    <div
      ref={ref}
      id={styles['dropdown-toggle']}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <div className={styles.name}>
        <span
          style={{
            fontWeight: 'bold',
            paddingRight: '1rem'
          }}
        >
          {user.name}
        </span>
      </div>
      <div>
        <img 
          src={user.picture}
          alt={user.name}
          style={{
            width: '48px',
            height: '48px',
            borderRadius: '24px'
          }}
        />
      </div>
    </div>
  ));

  const logoutClick = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('code');
    localStorage.removeItem('state');
    logout({ logoutParams: { returnTo: window.location.origin } });
  }

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle}/>
      <Dropdown.Menu>
        <Dropdown.Item
          eventKey="1"
          disabled
        >
          {user.email}
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="2"
          onClick={logoutClick}
        >
          Logout
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Profile;
