import { useEffect, useRef, useState } from "react";
import { Alert, Button, Form, Offcanvas } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from 'yup';

import { addTagToReservation, getTags, postTag, removeTagWithDelete } from "../../../services/api/v2/meta";
import Loader from "../loader/loader";
import FieldColor from "./color";
import TagBadge from "./tag";

import { Reservation } from "../../../types/v2/reservation";
import Tag from "../../../types/v2/tag";
import { useAuth0 } from "@auth0/auth0-react";

const TagCanvas = ({
  reservation,
  show,
  close,
}: {
  reservation: Reservation,
  show: boolean,
  close: () => void
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const [currentReservation, setCurrentReservation] = useState<Reservation>(reservation);
  const [filteredTags, setFilteredTags] = useState<Tag[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const formik: any = useRef();
  const validationSchema = yup.object({
    name: yup.string().required('Label is required').max(20),
    color: yup.string().required('Select a color').max(7).matches(/^#([0-9a-f]{3}|[0-9a-f]{6})$/i)
  });

  const reloadTag = () => {
    setIsLoading(true);
    getTags(getAccessTokenSilently)
      .then(async (response: Response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      }).then((tags: Tag[]) => {
        setFilteredTags(tags.filter((tag: Tag) => {
          if (currentReservation.tags.findIndex((t: Tag) => t.tag_id === tag.tag_id) === -1) {
            return tag;
          }
          return null;
        }));
        setIsLoading(false);
      }).catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  }

  useEffect(() => {
    setCurrentReservation(reservation);
  }, [reservation])

  useEffect(() => {
    reloadTag();
  }, [currentReservation])

  const handleSubmit = (form: any) => {
    postTag(getAccessTokenSilently, {
      ...form,
      reservationId: currentReservation.reservation_id
    }).then(async (response: Response) => {
      if (!response.ok) throw new Error(await response.text());
      return response.json();
    }).then((reservationHasTag: any) => {
      const r = {...currentReservation};
      r.tags.push({
        ...form,
        tag_id: reservationHasTag.tag_id
      });
      setCurrentReservation(r);
      if (formik && formik.current) {
        formik.current.resetForm();
      }
    }).catch((err) => setError(err.message));
  }

  const selectTag = (tag: Tag) => {
    addTagToReservation(getAccessTokenSilently, tag.tag_id as number, currentReservation.reservation_id)
      .then(async (response: Response) => {
        if (!response.ok) throw new Error(await response.text());
        const r = {...currentReservation};
        r.tags.push(tag);
        setCurrentReservation(r);
        reloadTag();
      }).catch((err) => setError(err.message))
  }

  const removeTag = (tag: Tag) => {
    removeTagWithDelete(getAccessTokenSilently, tag.tag_id as number, currentReservation.reservation_id)
      .then(async (response: Response) => {
        if (!response.ok) throw new Error(await response.text());
        const r = {...currentReservation};
        r.tags = currentReservation.tags.filter((t: Tag) => t.tag_id !== tag.tag_id);
        setCurrentReservation(r);
        reloadTag();
      }).catch((err) => setError(err.message))
  }

  return (
    <div>
      <Offcanvas
        id="tagCanvas"
        show={show}
        onHide={close}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Tags</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            {currentReservation.tags.length > 0 &&
            <>
              <div>
                <h5>Used</h5>
                <div>
                {currentReservation.tags.map((tag: Tag) => {
                  return <TagBadge key={`used${tag.tag_id}`} tag={tag} remove={(t) => removeTag(t)} />
                })}
                </div>
              </div>
              <hr />
            </>
            }
            {filteredTags.length > 0 &&
            <>
              <div>
                <h5>Select Existing</h5>
                {error &&
                  <Alert variant="danger">
                    {error}
                  </Alert>
                }
                <Loader isLoading={isLoading}>
                  <>
                    {filteredTags.map((tag: Tag) => {
                      return <TagBadge key={`found${tag.tag_id}`} tag={tag} select={(t) => selectTag(t)} />;
                    })}
                  </>
                </Loader>
              </div>
              <hr />
            </>
            }
            <div>
              <h5>Create New</h5>
              <Formik
                innerRef={formik}
                initialValues={{
                  name: "",
                  color: ""
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {(formikProps: any) => {
                  return (
                    <Form onSubmit={formikProps.handleSubmit}>
                      <Form.Control
                        type="text"
                        placeholder="Enter label"
                        required
                        name="name"
                        value={formikProps.values.name}
                        onChange={formikProps.handleChange}
                        isInvalid={!!formikProps.errors.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formikProps.errors.name}
                      </Form.Control.Feedback>
                      <FieldColor formikProps={formikProps} />
                      <Button
                        variant="primary"
                        className="btn-white mt-3"
                        type="submit"
                      >
                        Save
                      </Button>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}

export default TagCanvas;