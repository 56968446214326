import { apiBase, GetTokenType, SendRequest } from ".";
import Tag from "../../../types/v2/tag";

export const getTags = (getToken: GetTokenType) => {
  return SendRequest(
    getToken,
    'GET',
    `${apiBase}/meta/tags/`
  );
}

export const postTag = (getToken: GetTokenType, tag: Tag) => {
  return SendRequest(
    getToken,
    'POST',
    `${apiBase}/meta/tag/`,
    JSON.stringify(tag)
  );
}

export const addTagToReservation = (getToken: GetTokenType, tagId: number, reservationId: number) => {
  return SendRequest(
    getToken,
    'POST',
    `${apiBase}/meta/tag/${tagId}`,
    JSON.stringify({ reservationId })
  );
}

export const removeTagWithDelete = (getToken: GetTokenType, tagId: number, reservationId: number) => {
  return SendRequest(
    getToken,
    'DELETE',
    `${apiBase}/meta/tag/${tagId}`,
    JSON.stringify({ reservationId })
  );
}

export const getMonthData = (getToken: GetTokenType, date: string) => {
  return SendRequest(
    getToken,
    'GET',
    `${apiBase}/meta/month/${date}`
  );
}