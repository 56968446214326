import { apiBase, GetTokenType, SendRequest } from ".";
import Customer from "../../../types/v2/customer";

export const getCustomer = (getToken: GetTokenType, id: string) => {
  return SendRequest(
    getToken,
    'GET',
    `${apiBase}/customer/${id}`
  );
};

export const putCustomer = (getToken: GetTokenType, customer: Customer) => {
  return SendRequest(
    getToken,
    'PUT',
    `${apiBase}/customer/${customer.customer_id}`,
    JSON.stringify(customer)
  );
};

export const deleteCustomer = (getToken: GetTokenType, cid: number, rid: number) => {
  return SendRequest(
    getToken,
    'DELETE',
    `${apiBase}/customer/${cid}`,
    JSON.stringify({ reservationId: rid })
  );
}