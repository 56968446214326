import { apiBase, GetTokenType, SendRequest } from ".";
import { ReservationFilters } from "../../../types/v2/reservation";

export const getReservations = (
  getToken: GetTokenType,
  filters: ReservationFilters,
  sizePerPage: number,
  page: number,
  signal?: AbortSignal
) => {
  let params = `?sizePerPage=${sizePerPage}&page=${page - 1}`;

  for (const key in filters) {
    params += `&${key}=${filters[key as keyof ReservationFilters]}`
  }

  return SendRequest(
    getToken,
    'GET',
    `${apiBase}/reservation${params}`,
    '{}',
    signal
  );
};

export const postReservationFraudulent = (
  getToken: GetTokenType, 
  id: number
) => {
  return SendRequest(
    getToken,
    'POST',
    `${apiBase}/reservation/${id}/fraudulent`,
    '{}',
  );
}