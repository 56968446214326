import { useEffect, useState } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";

import { GoogleAccount, GoogleAccountResponse, GoogleLocation, GoogleLocationResponse, GoogleReview, GoogleReviewResponse } from "../../../types/v2/google";
import { getGoogleAccounts, getGoogleLocations, getGoogleReviews } from "../../../services/api/v2/google";
import Loader from "../../common/loader/loader";
import Stat from "../../common/stat";
import { useAuth0 } from "@auth0/auth0-react";

interface Location extends GoogleLocation {
  reviews: GoogleReview[];
  stats: {
    oneWeekAgo: number;
    twoWeeksAgo: number;
    oneMonthAgo: number;
    twoMonthsAgo: number;
  }
}

interface Account extends GoogleAccount {
  locations: Location[];
}

const Reviews = () => {
  const { getAccessTokenSilently } = useAuth0();
  
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);

  const getIdFromName = (goo: GoogleAccount | GoogleLocation) => {
    return goo.name.split("/")[1];
  }
  
  useEffect(() => {
    let account: Account | undefined = undefined;
    getGoogleAccounts(getAccessTokenSilently)
      .then((response: Response) => {
        console.log("GOOGLE", response);
        return response.json();
      }).then((response: GoogleAccountResponse) => {
        account = {
          ...response.accounts[0],
          locations: []
        };
        return getIdFromName(account);
      }).then((accountId) => {
        return getGoogleLocations(getAccessTokenSilently, accountId);
      }).then((response: Response) => {
        return response.json();
      }).then((response: GoogleLocationResponse) => {
        if (account) {
          account.locations.push({
            ...response.locations[0],
            reviews: [],
            stats: {
              oneWeekAgo: 0,
              twoWeeksAgo: 0,
              oneMonthAgo: 0,
              twoMonthsAgo: 0
            }
          });
          return getIdFromName(account.locations[0]);
        }
        return undefined;
      }).then((locationId: string | undefined) => {
        if (!locationId) throw new Error("location id is undefined");
        if (!account) throw new Error("Account is undefined");
        return getGoogleReviews(getAccessTokenSilently, getIdFromName(account), locationId);
      }).then((response: Response) => {
        return response.json();
      }).then((response: GoogleReviewResponse) => {
        if (account) {
          account.locations[0].reviews = response.reviews;
          const oneWeekAgo = response.reviews
            .filter((review: GoogleReview) => moment(review.createTime).isAfter(moment().subtract(1, 'weeks'))).length;
          const twoWeeksAgo = response.reviews
            .filter((review: GoogleReview) => moment(review.createTime).isAfter(moment().subtract(2, 'weeks'))).length - oneWeekAgo;
          const oneMonthAgo = response.reviews
            .filter((review: GoogleReview) => moment(review.createTime).isAfter(moment().subtract(1, 'month'))).length;
          const twoMonthsAgo = response.reviews
            .filter((review: GoogleReview) => moment(review.createTime).isAfter(moment().subtract(2, 'month'))).length - oneMonthAgo;

          account.locations[0].stats = {
            oneWeekAgo,
            twoWeeksAgo,
            oneMonthAgo,
            twoMonthsAgo 
          }
          setAccounts([account]);
          setIsLoading(false);
        }
      }).catch((error) => {
        setError(error.message);
        setIsLoading(false);
      });
  }, [setAccounts, setIsLoading]);

  return (
    <>
      <Loader isLoading={isLoading}>
        <Stat
          title="Reviews"
        >
          {error &&
          <span className="error">{error}</span>
          }
          {accounts.map((account: Account) => {
            return(
              <div key={account.name}>
                {account.locations.map((location: Location) => {
                  const weekPercent = (location.stats.oneWeekAgo / location.stats.twoWeeksAgo * 100) - 100;
                  const monthPercent = (location.stats.oneMonthAgo / location.stats.twoMonthsAgo * 100) - 100;
                  return (
                    <div key={location.name}>
                      <div>
                        <span>{location.storefrontAddress.locality}</span>
                      </div>
                      <hr />
                      <div>
                        <b>Weekly</b>
                        <div>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>Last week</div>
                            <div>
                              <b>{location.stats.oneWeekAgo}</b>
                            </div>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                            <div>
                              <FontAwesomeIcon
                                icon={weekPercent < 0 ? faArrowDown : faArrowUp}
                                style={{ color: weekPercent < 0 ? "#b38080" : "#88B380" }}
                              />{" "}{weekPercent.toFixed(2)}% 
                            </div>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>Two weeks ago</div>
                            <div>{location.stats.twoWeeksAgo}</div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div>
                        <b>Monthly</b>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div>Last month</div>
                          <div>
                            <b>{location.stats.oneMonthAgo}</b>
                          </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                          <div>
                            <FontAwesomeIcon
                              icon={monthPercent < 0 ? faArrowDown : faArrowUp}
                              style={{ color: monthPercent < 0 ? "#b38080" : "#88B380" }}
                            />{" "}{monthPercent.toFixed(2)}% 
                          </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div>Two months ago</div>
                          <div>{location.stats.twoMonthsAgo}</div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </Stat>
      </Loader>
    </>
  );
}

export default Reviews;