import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";

import Loader from "../../common/loader/loader";
import Stat from "../../common/stat";
import { formatTime } from "../../common/helpers";
import { getRoomKStats } from "../../../services/api/v2/stats";
import { useAuth0 } from "@auth0/auth0-react";

interface RoomKStat {
  game_report_id: number;
  reservation_id: number;
  success: number;
  start_time: string;
  end_time: string;
  player_count: number;
  duration: number;
  game_report_value_id: number;
  value: string;
  game_report_value_definition_id: number;
  name: string;
}

interface RoomKStats { [key:number]: RoomKStat[] }

const RoomK = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [statsPerReport, setStatsPerReport] = useState<RoomKStats | undefined>(undefined);
  const [statsPerDefinition, setStatsPerDefinition] = useState<RoomKStats | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    setError(undefined);

    getRoomKStats(getAccessTokenSilently)
      .then(async (response: Response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      }).then((stats: RoomKStat[]) => {
        setIsLoading(false);

        const repSts: RoomKStats = {};
        const defSts: RoomKStats = {};

        stats.forEach((stat: RoomKStat) => {
          const repKey = stat.game_report_id;
          const defKey = stat.game_report_value_definition_id;
          
          if (!defSts[defKey]) {
            defSts[defKey] = [stat];
          } else {
            defSts[defKey].push(stat);
          }

          if (!repSts[repKey]) {
            repSts[repKey] = [stat];
          } else {
            repSts[repKey].push(stat);
          }
        });

        setStatsPerReport(repSts);
        setStatsPerDefinition(defSts);
      }).catch((err: Error) => {
        setIsLoading(false);
        setError(err.message);
      });
  }, [setStatsPerDefinition, setIsLoading, setError]);

  const average = (a: RoomKStat[]) => {
    let sum = 0;
    a.forEach((stat: RoomKStat) => {
      sum += parseInt(stat.value);
    });
    return sum / a.length;
  }

  const avgDuration = () => {
    if (!statsPerReport) return;

    let sum = 0;
    const keys = Object.keys(statsPerReport);
    keys.forEach((key: string) => {
      sum += statsPerReport[parseInt(key)][0].duration;
    });
    return formatTime(sum / keys.length)
  }

  return (
    <>
      {error &&
      <Alert variant="danger">
        {error}
      </Alert>
      }
      <Loader isLoading={isLoading}>
        {statsPerDefinition && statsPerReport &&
        <Stat title={`Room K`}>
          <div>
            <b>Duration</b>
            <div>Average Duration: {avgDuration()}</div>
            <br/>
            <b>Mess Room</b>
            <div>Average Time: {formatTime(average(statsPerDefinition[58]))}</div>
            <div>Finished: {statsPerDefinition[59].filter((stat: RoomKStat) => stat.value === "False").length}</div>
            <div>Auto unlock: {statsPerDefinition[59].filter((stat: RoomKStat) => stat.value === "True").length}</div>
            <br/>
            <b>Hyperdrive</b>
            <div>Average Time: {formatTime(average(statsPerDefinition[60]))}</div>
            <div>Finished: {statsPerDefinition[61].filter((stat: RoomKStat) => stat.value === "False").length}</div>
            <div>Auto unlock: {statsPerDefinition[61].filter((stat: RoomKStat) => stat.value === "True").length}</div>
            <br/>
            <b>Docking</b>
            <div>Average Time: {formatTime(average(statsPerDefinition[62]))}</div>
            <div>Finished: {statsPerDefinition[63].filter((stat: RoomKStat) => stat.value === "False").length}</div>
            <div>Auto unlock: {statsPerDefinition[63].filter((stat: RoomKStat) => stat.value === "True").length}</div>
            <br/>
            <b>Checkpoint Reload</b>
            <div>Average Checkpoint Reload: {average(statsPerDefinition[64]).toFixed(2)}</div>
          </div>
        </Stat>
        }
      </Loader>
    </>
  )
}

export default RoomK;