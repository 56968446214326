import { ReactNode, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

import { getMonthData } from "../../../services/api/v2/meta";

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import styles from "./index.module.css";
import { useAuth0 } from "@auth0/auth0-react";

interface DayData {
  [key: string] : {
    reservationCount: number,
    ticketCount: number
  }
}

const DPicker = ({
  value,
  disabled,
  onChange
}: {
  value: Date;
  disabled: boolean;
  onChange: (value: any) => void;
}) => {
  const { getAccessTokenSilently } = useAuth0();
  
  const [day, setday] = useState<string | undefined>(undefined);
  const [dayData, setDayData] = useState<DayData>({});

  useEffect(() => {
    const d = moment.utc(value).format('YYYY-MM-DD');
    if (!day || d !== day) {
      getMonthData(getAccessTokenSilently, d)
        .then(async (response: Response) => {
          if (!response.ok) throw new Error(await response.text());
          return response.json();
        }).then((data: DayData) => {
          setday(d);
          setDayData(data);
        }).catch((err) => console.error(err));
    }
  }, [value]);

  const formatDay = ({ activeStartDate, date, view }: { activeStartDate: Date, date: Date, view: any }): ReactNode => {
    if (view === 'month') {
      const data = dayData[moment(date).format('YYYY-MM-DD')];
      if (data && data.reservationCount > 0) {
        const isToday = moment(date).startOf('day').isSame(new Date(), 'day') && moment(value).startOf('day').isSame(new Date(), 'day');
        return (
          <div style={{
            position: 'absolute',
            fontSize: '10px',
            color: isToday ? 'white' : '#CCCCCC',
            bottom: 0,
            left: '17.5px',
            display: 'flex',
            justifyContent: 'center',
            gap: '3px'
          }}>
            {data.reservationCount}-{data.ticketCount}
          </div>
        )
      }
    }
  }

  return (
    <div className={styles.dpicker}>
      <DatePicker
        onChange={onChange}
        value={value}
        disabled={disabled}
        name="date"
        className="btn btn-primary"
        calendarIcon={<FontAwesomeIcon icon={faCalendarAlt}/>}
        tileClassName={styles.tile}
        tileContent={formatDay}
      />
    </div>
  )
}

export default DPicker;