import { useState } from 'react';
import {
  Route, 
  BrowserRouter as Router,
  Routes
} from 'react-router-dom';

import UserContext from './context/user';

import NotFound from './components/common/notFound';
import AuthenticationChecker from './components/authenticationChecker';
import Header from './components/header';
import Dashboard from './components/booking/dashboard';
import CustomerDetails from './components/booking/customer';
import Stats from './components/stats';
import Tvs from './components/tvs';
import GameReport from './components/gameReport';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function App() {
  const [user, setUser] = useState(null);
  return (
    <div className="App">
      <UserContext.Provider value={{ user: user, setUser: setUser }}>
        <AuthenticationChecker>
            <Router>
              <Header/>
              <div className="admin-body">
                <Routes>
                  <Route path="game-reports" element={<GameReport />} />
                  <Route path="tvs" element={<Tvs />} />
                  <Route path="stats" element={<Stats />} />
                  <Route path="/reservation/:id/customer/:cid" element={<CustomerDetails />} />
                  <Route path="/" element={<Dashboard />} />
                  <Route path='*' element={<NotFound />} />
                </Routes>
              </div>
            </Router>
        </AuthenticationChecker>
      </UserContext.Provider>
    </div>
  );
}

export default App;
