import { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Formik } from "formik";
import { useAuth0 } from "@auth0/auth0-react";

import { getCustomer, putCustomer } from "../../../services/api/v2/customer";
import Back from "../../common/back/button";

import Customer from "../../../types/v2/customer";

interface CustomerProps {
  customerGroupId?: number;
  customer?: Customer;
  callbackPath?: any;
  save?: any;
  isStandalone?: boolean;
  contact?: any;
}

const CustomerDetails = (props: CustomerProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const { getAccessTokenSilently } = useAuth0();

  const [customer, setCustomer] = useState<Customer | undefined>(props.customer || location.state.customer);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const back = `/`;
  let isValidated = false;

  useEffect(() => {
    if (!isLoading && !customer) {
      fetch();
    }
  });

  const fetch = () => {
    setError(undefined);
    setIsLoading(true);
    if (params.cid) {
      getCustomer(getAccessTokenSilently, params.cid)
        .then(async (response: Response) => {
          if (!response.ok) throw new Error(await response.text());
          return response.json();
        }).then((cust: Customer) => {
          setCustomer(cust);
          setIsLoading(false);
        }).catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }

  const handleSubmit = (cust: Customer, { setErrors, resetForm }: { setErrors: any, resetForm: any }) => {
    putCustomer(getAccessTokenSilently, cust)
      .then(async (response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.text();
      }).then(() => {
        navigate(back);
      }).catch((err) => {
        setErrors({
          username: err.message
        });
      });
  }

  const schema = yup.object({
    username: yup.string().required('Please provide an in game username.'),
    email: yup.string().email('Invalid email').required('Email is required'),
    firstname: yup.string().required('First name is required'),
    lastname: yup.string().required('Last name is required'),
    isNewsletterSubscribed: yup.boolean()
  });

  if (!customer) {
    return <span>No Customer Found</span>;
  }

  return (
    <div className="app-container customer">
      {error &&
      <Alert variant="danger">
        {error.message}
      </Alert>
      }
      <Back route={back} classes="mb-3"/>
      {!isLoading &&
      <Formik
        validationSchema={schema}
        initialValues={customer}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form
            noValidate
            validated={isValidated}
            onSubmit={formikProps.handleSubmit}
            autoComplete="off"
          >
            <Form.Group controlId="username">
              <Form.Label>Username (In Game)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Username"
                required
                name="username"
                value={formikProps.values.username || ''}
                onChange={formikProps.handleChange}
                isInvalid={!!formikProps.errors.username}
              />
              <Form.Control.Feedback type="invalid">
                {formikProps.errors.username}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter Email"
                required
                name="email"
                value={formikProps.values.email || ''}
                onChange={formikProps.handleChange}
                isInvalid={!!formikProps.errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {formikProps.errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Row>
              <Col>
                <Form.Group controlId="firstname">
                  <Form.Label>First name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Firstname"
                    required
                    name="firstname"
                    value={formikProps.values.firstname || ''}
                    onChange={formikProps.handleChange}
                    isInvalid={!!formikProps.errors.firstname}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formikProps.errors.firstname}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="lastname">
                  <Form.Label>Last name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Lastname"
                    required
                    name="lastname"
                    value={formikProps.values.lastname || ''}
                    onChange={formikProps.handleChange}
                    isInvalid={!!formikProps.errors.lastname}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formikProps.errors.lastname}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Button variant="primary" type="submit">
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      }
    </div>
  )
}

export default CustomerDetails;