import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import useWindowSize from '../common/hooks/useWindowSize';
import UserContext from '../../context/user';

import Profile from '../profile';

const Header = () => {
  const location = useLocation();
  const [width] = useWindowSize();
  const { user, setUser } = useContext(UserContext);

  console.log(user);

  return (
    <header>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center'
        }}>
          <Link
            to="/"
            style={{
              lineHeight: 1,
              fontFamily: 'teko',
              fontWeight: 'bold',
              fontSize: '36px',
              padding: '13px 20px 13px 0'
            }}
          >
            HIGGS{width > 660 ? ` - Booking Administration` : ''}
          </Link>
          <Link
            to="/game-reports"
            style={{
              height: '74px',
              padding: '23px 20px'
            }}
            className={location.pathname === "/game-reports" ? "active" : "" }
          >
            Game Reports
          </Link>
          <Link
            to="/tvs"
            style={{
              height: '74px',
              padding: '23px 20px'
            }}
            className={location.pathname === "/tvs" ? "active" : "" }
          >
            TVs
          </Link>
          <Link
            to="/stats"
            style={{
              height: '74px',
              padding: '23px 20px'
            }}
            className={location.pathname === "/stats" ? "active" : "" }
          >
            Stats
          </Link>
        </div>
        <Profile />
      </div>
    </header>
  )
}

export default Header;