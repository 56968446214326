import { apiBase, GetTokenType, SendRequest } from ".";

export const showScores = (getToken: GetTokenType, tv: number, reservation: number) => {
  return SendRequest(
    getToken,
    'POST',
    `${apiBase}/tv-app/${tv}/reservation/${reservation}/show-scores`
  );
};

export const showMessage = (
  getToken: GetTokenType,
  tv: number,
  name: string,
  message: string,
  length: number
) => {
  const body = {
    name,
    message,
    length
  };

  return SendRequest(
    getToken,
    'POST',
    `${apiBase}/tv-app/${tv}/message`,
    JSON.stringify(body)
  )
}

export const resetTv = (getToken: GetTokenType, tv: number) => {
  return SendRequest(
    getToken,
    'POST',
    `${apiBase}/tv-app/${tv}/reset`
  );
};