import { useEffect, useState } from "react";
import { Alert, Badge } from "react-bootstrap";
import moment from "moment";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";

import { GameReport } from "../../types/v2/gameReport";
import { Reservation } from "../../types/v2/reservation";

import { getGameReports } from "../../services/api/v2/gameReport";
import TableControls from "../booking/dashboard/table-controls";
import Loader from "../common/loader/loader";
import { defaultSearchValues, SearchMeta } from "../common/search/search";

import styles from './index.module.css';
import Pagination from "../common/pagination";
import { useAuth0 } from "@auth0/auth0-react";

interface Report extends Reservation {
  game_reports: GameReport[],
  slot: string,
  type: string
}

const GameReportList = () => {
  const { getAccessTokenSilently } = useAuth0();
  
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reports, setReports] = useState<Report[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [error, setError] = useState<string | undefined>(undefined);
  const [dateOption, setDateOption] = useState<string>("all");
  const [filterButtonDate, setFilterButtonDate] = useState<Date>(new Date());
  const [experienceType, setExperienceType] = useState<string>("all");
  const [currentSearch, setCurrentSearch] = useState<SearchMeta>(localStorage.getItem('currentSearch') 
    ? JSON.parse(localStorage.getItem('currentSearch') as string) as SearchMeta
    : defaultSearchValues
  );

  const sizePerPage: number = 30;
  const columns: any[] = [
    {
      header: 'Uuid',
      accessorKey: 'game_reports',
      cell: (info: any) => {
        const value = info.getValue();
        return value.uuid
      }
    },
    {
      header: 'Email',
      accessorKey: 'email'
    },
    {
      header: 'Type',
      accessorKey: 'type',
      cell: (info: any) => {
        const value = info.getValue();
        let display: any = "Unknown";

        switch (value) {
          case "TF":
            display = <Badge>Tidal Force</Badge>;
            break;
          case "SD":
            display = <Badge bg="warning">Space Defense</Badge>;
            break;
          case "SV":
            display = <Badge bg="success">Asteroid Skirmish</Badge>;
            break;
          case "HO":
            display = <Badge bg="danger">Room-K</Badge>;
            break;
          
          default:
            display = "Unknown";
            break;
        }

        return display
      }
    }
  ]

  const searchReports = () => {
    if (!currentSearch.query || currentSearch.query === "") return;

    setIsLoading(true);
    setError(undefined);

    const filters = {
      range: dateOption,
      [currentSearch.type as string]: currentSearch.query,
      ...experienceType !== "all" ? { type: experienceType } : {}
    };

    getGameReports(getAccessTokenSilently, filters, sizePerPage, page)
      .then(async (response: Response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      }).then((data) => {
        const _res: Report[] = data.rows;
        setReports(_res);
        setCount(data.count);
        setIsLoading(false);
      }).catch(async (error) => {
        setError(error.message);
      });
  }

  const getReports = (date?: Date) => {
    setIsLoading(true);
    setError(undefined);

    const d = moment(date || filterButtonDate);

    const filters = dateOption !== "all" ? {
      range: dateOption,
      day: d.date(),
      month: d.month() + 1,
      year: d.year(),
      ...experienceType !== "all" ? { type: experienceType } : {}
    } : {
      range: dateOption,
      // [currentSearch.type as string]: currentSearch.query,
      ...experienceType !== "all" ? { type: experienceType } : {}
    };

    const abortController = new AbortController();
    getGameReports(getAccessTokenSilently, filters, sizePerPage, page, abortController.signal)
      .then(async (response: Response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      }).then((data) => {
        const _res: Report[] = data.rows;
        setReports(_res);
        setCount(data.count);
        setIsLoading(false);
      }).catch(async (error) => {
        setError(error.message);
      });

    return () => {
      abortController.abort();
    };
  }

  const reloadRecord = (date?: Date) => {
    if (currentSearch.active && currentSearch.query !== "") {
      searchReports();
    } else {
      getReports(date);
    }
  }

  const onTableChange = (page: number) => {
    setPage(page);
  }

  useEffect(() => {
    reloadRecord();
  }, [currentSearch.query, currentSearch.type, page, filterButtonDate, dateOption, experienceType]);

  const table = useReactTable({
    data: reports,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    rowCount: count
  });

  return (
    <div className={styles.container}>
      {error &&
        <Alert
          variant="danger"
          dismissible={true}
        >
          {error}
        </Alert>
      }
      <TableControls
        title="Game Reports"
        reload={reloadRecord}
        filterButtonDate={filterButtonDate}
        dateOption={dateOption}
        setDateOption={setDateOption}
        setFilterButtonDate={setFilterButtonDate}
        setCurrentSearch={setCurrentSearch}
        experienceType={experienceType}
        setExperienceType={setExperienceType}
        setPage={setPage}
        setCount={setCount}
      />
      <Loader
        isLoading={isLoading}
        centered={true}
      >
      <table>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          totalCount={count}
          currentPage={page}
          pageSize={sizePerPage}
          onPageChange={onTableChange}
          siblingCount={2}
          className={styles.pagination}
        />
      </Loader>
    </div>
  )
}

export default GameReportList;