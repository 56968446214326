import { apiBase, GetTokenType, SendRequest } from ".";
import { ReservationFilters } from "../../../types/v2/reservation";

export const getGameReports = (
  getToken: GetTokenType,
  filters: ReservationFilters,
  sizePerPage: number,
  page: number,
  signal?: AbortSignal
) => {
  let params = `?sizePerPage=${sizePerPage}&page=${page}`;

  for (const key in filters) {
    params += `&${key}=${filters[key as keyof ReservationFilters]}`
  }

  return SendRequest(
    getToken,
    'GET',
    `${apiBase}/gamereport${params}`,
    '{}',
    signal
  );
};