import { apiBase, GetTokenType, SendRequest } from "."

export const moveWaiver = (getToken: GetTokenType, wid: number, rid: number) => {
  return SendRequest(
    getToken,
    'PUT',
    `${apiBase}/waiver/${wid}`,
    JSON.stringify({ reservation_id: rid })
  )
}

export const changeWaiver = (getToken: GetTokenType, id: number, attribute: string, value: string) => {
  return SendRequest(
    getToken,
    'PUT',
    `${apiBase}/waiver/${id}?${attribute}=${value}`,
    '{}'
  )
}

export const deleteWaiver = (getToken: GetTokenType, id: number) => {
  return SendRequest(
    getToken,
    'DELETE',
    `${apiBase}/waiver/${id}`
  );
}